import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, Container } from "@mui/material";
import { useSelector } from "react-redux";
import Header from "components/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import forageImage from "../../assets/forage.png";

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

const DonneesTechniques = () => {
  const mode = useSelector((state) => state.global.mode);

  const [formData, setFormData] = useState({
    selectedDate: getCurrentDate(),
    indexSpdm: 0,
    indexSpfm: 0,
    nombreMp: 0,
    nombreMd: 0,
    stockDisponible: 0,
    perteReseau: 0,
    observations: "",
  });

  useEffect(() => {
    const fetchConsommationTotal = async () => {
      try {
        const response = await fetch(
          "https://gestionforage.terangapp.com/api/consommations/total"
        );
        if (!response.ok) {
          throw new Error(`Erreur HTTP! statut: ${response.status}`);
        }
        const data = await response.json();
        setFormData((prevData) => ({
          ...prevData,
          nombreMd: data.totalConsommation,
        }));
        toast.success();
      } catch (error) {
        toast.error(
          "Erreur lors de la récupération de la consommation totale. Veuillez réessayer."
        );
      }
    };

    fetchConsommationTotal();
  }, []);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      nombreMp: prevData.indexSpdm - prevData.indexSpfm,
    }));
  }, [formData.indexSpdm, formData.indexSpfm]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      perteReseau:
        prevData.nombreMp - prevData.nombreMd - prevData.stockDisponible,
    }));
  }, [formData.nombreMp, formData.nombreMd, formData.stockDisponible]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        name === "selectedDate" || name === "observations"
          ? value
          : value === ""
          ? ""
          : Number(value),
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch("https://gestionforage.terangapp.com/api/donnees", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`Erreur HTTP! statut: ${response.status}`);
      }

      const data = await response.json();
      console.log("Form submitted:", data);

      toast.success("Formulaire soumis avec succès.");

      setFormData({
        selectedDate: getCurrentDate(),
        indexSpdm: 0,
        indexSpfm: 0,
        nombreMp: 0,
        nombreMd: 0,
        stockDisponible: 0,
        perteReseau: 0,
        observations: "",
      });
    } catch (error) {
      console.error("Erreur lors de la soumission du formulaire!", error);
      toast.error(
        "Erreur lors de la soumission du formulaire. Veuillez réessayer."
      );
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 2, ml: 3 }}>
      <Header
        title=" Ajouter une donnée technique"
        subtitle="La valeur 0 est rempli par défaut"
        sx={{ mb: 5 }}
      />
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid item xs={8}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Date"
                  type="date"
                  name="selectedDate"
                  value={formData.selectedDate}
                  onChange={handleChange}
                  required
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {[
                "indexSpdm",
                "indexSpfm",
                "nombreMp",
                "nombreMd",
                "stockDisponible",
                "perteReseau",
              ].map((field) => (
                <Grid item xs={6} key={field}>
                  <TextField
                    label={field.replace(/([A-Z])/g, " $1").trim()}
                    type="number"
                    name={field}
                    value={formData[field]}
                    onChange={handleChange}
                    required
                    fullWidth
                    InputProps={
                      field === "nombreMd" || field === "perteReseau"
                        ? { readOnly: true }
                        : {}
                    }
                    disabled={field === "nombreMd" || field === "perteReseau"}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <TextField
                  label="Observations"
                  name="observations"
                  value={formData.observations}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color={mode === "dark" ? "secondary" : "primary"}
                  size="large"
                  sx={{
                    width: "100%",
                  }}
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={4} container alignItems="center" justifyContent="center">
          <img
            src={forageImage}
            alt="Forage"
            style={{
              width: "100%",
              maxWidth: "200px",
              display: "block",
              margin: "0 auto",
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default DonneesTechniques;
