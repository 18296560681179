import React, { useState, useEffect, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { DownloadOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  useTheme,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useFetchSortieCaisse from "./useFetchSortieCaisseList";

// Fonction pour convertir les dates en format dd-MM-yyyy
const formatDateToDDMMYYYY = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day}-${month}-${year}`;
};

// Fonction pour convertir les dates en format yyyy-MM-dd
const convertToYYYYMMDD = (dateString) => {
  const [day, month, year] = dateString.split('-');
  return `${year}-${month}-${day}`;
};

// Récupérer le premier jour du mois précédent en dd-MM-yyyy
const getFirstDayOfMonthBefore = () => {
  const currentDate = new Date();
  currentDate.setUTCMonth(currentDate.getUTCMonth() - 1); // Met au mois précédent en UTC
  currentDate.setUTCDate(1); // Met au 1er jour du mois précédent en UTC
  const firstDay = currentDate.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(firstDay); // Convertir en dd-MM-yyyy
};

const getFirstDayOfMonth = () => {
  const currentDate = new Date();
  // Set the currentDate to the first day of the current month
  currentDate.setUTCDate(1);
  // Move back to the previous month
  currentDate.setUTCMonth(currentDate.getUTCMonth() - 1);
  
  const firstDay = currentDate.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(firstDay); // Convert it to dd-MM-yyyy
};


// Récupérer le dernier jour du mois précédent en dd-MM-yyyy
const getLastDayOfMonth = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0)
  );
  const lastDayFormatted = lastDay.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(lastDayFormatted); // Convertir en dd-MM-yyyy
};

// Récupérer le premier jour du mois précédent en dd-MM-yyyy
const getFirstDayOfPreviousMonth = () => {
  const currentDate = new Date();
  currentDate.setUTCMonth(currentDate.getUTCMonth() - 2); // Met au mois précédent en UTC
  currentDate.setUTCDate(1); // Met au 1er jour du mois précédent en UTC
  const firstDay = currentDate.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(firstDay); // Convertir en dd-MM-yyyy
};

// Récupérer le dernier jour du mois précédent en dd-MM-yyyy
const getLastDayOfPreviousMonth = () => {
  const currentDate = new Date();
  const lastDay = new Date(
    Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth() -1, 0)
  );
  const lastDayFormatted = lastDay.toISOString().split("T")[0];
  return formatDateToDDMMYYYY(lastDayFormatted); // Convertir en dd-MM-yyyy
};

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

const useFetchAggregatedData = (startDate, endDate) => {
  const [aggregatedConsommations, setAggregatedConsommations] = useState([]);
  const [total, setTotal] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isDataReady, setIsDataReady] = useState(false);

  const fetchAggregatedData = useCallback(async () => {
    if (!startDate || !endDate) {
      toast.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetching(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/consommations/distribution",
        {
          params: { startDate, endDate },
        }
      );
      const dataWithIds = response.data.rows.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setAggregatedConsommations(dataWithIds);
      setTotal(response.data.total);
      setIsDataReady(true);
    } catch (error) {
      console.error("Échec de la récupération des données agrégées:", error);
      toast.error(
        "Échec de la récupération des données agrégées. Veuillez réessayer."
      );
      setIsDataReady(false);
    }
    setIsFetching(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchAggregatedData();
  }, [startDate, endDate, fetchAggregatedData]);

  return { aggregatedConsommations, total, isFetching, isDataReady };
};

// ====================================Banque=======================================
const useFetchAggregatedTransactions = (startDate, endDate) => {
  const [aggregatedTransactions, setAggregatedTransactions] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(null);
  const [isFetchingTransactions, setIsFetchingTransactions] = useState(false);
  const [isDataReadyTransactions, setIsDataReadyTransactions] = useState(false);

  const fetchAggregatedTransactions = useCallback(async () => {
    if (!startDate || !endDate) {
      toast.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetchingTransactions(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/transactions/map",
        {
          params: { startDate, endDate },
        }
      );
      const dataWithIds = response.data.rows.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setAggregatedTransactions(dataWithIds);
      setTotalTransactions(response.data.total);
      setIsDataReadyTransactions(true);
    } catch (error) {
      console.error("Échec de la récupération des données agrégées:", error);
      toast.error(
        "Échec de la récupération des données agrégées. Veuillez réessayer."
      );
      setIsDataReadyTransactions(false);
    }
    setIsFetchingTransactions(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchAggregatedTransactions();
  }, [startDate, endDate, fetchAggregatedTransactions]);

  return {
    aggregatedTransactions,
    totalTransactions,
    isFetchingTransactions,
    isDataReadyTransactions,
  };
};


// =====================================Fin Banque==================================

// ==============================Tresorerie Globale=================================
const useFetchAggregatedDataTresorerieGlobale = (startDate, endDate) => {
  const [
    aggregatedConsommationsTresorerieGlobale,
    setAggregatedConsommationsTresorerieGlobale,
  ] = useState([]);
  const [totalTresorerieGlobale, setTotalTresorerieGlobale] = useState(null);
  const [isFetchingTresorerieGlobale, setIsFetchingTresorerieGlobale] =
    useState(false);
  const [isDataReadyTresorerieGlobale, setIsDataReadyTresorerieGlobale] =
    useState(false);

const fetchAggregatedData = useCallback(async () => {
  if (!startDate || !endDate) {
    toast.error("Veuillez sélectionner une plage de dates.");
    return;
  }

  console.log("Fetching data with the following dates:");
  console.log("Start Date:", startDate);
  console.log("End Date:", endDate);

  setIsFetchingTresorerieGlobale(true);

  try {
    const response = await axios.get(
      "https://gestionforage.terangapp.com/api/consommations/tresoreriedebutmois",
      {
        params: { startDate: startDate, endDate: endDate },
      }
    );

    console.log("API Response:", response);

    if (response.data && response.data.rows && response.data.total) {
      const dataWithIds = response.data.rows.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      console.log("Formatted Data with IDs:", dataWithIds);

      setAggregatedConsommationsTresorerieGlobale(dataWithIds);
      setTotalTresorerieGlobale(response.data.total);
      setIsDataReadyTresorerieGlobale(true);
    } else {
      console.error("Format de réponse API invalide");
      toast.error("Format de réponse API invalide. Veuillez réessayer.");
    }
  } catch (error) {
    console.error("Échec de la récupération des données agrégées:", error);
    toast.error(
      "Échec de la récupération des données agrégées. Veuillez réessayer."
    );
    setIsDataReadyTresorerieGlobale(false);
  }

  setIsFetchingTresorerieGlobale(false);
}, [startDate, endDate]);

  useEffect(() => {
    fetchAggregatedData();
  }, [startDate, endDate, fetchAggregatedData]);

  return {
    aggregatedConsommationsTresorerieGlobale,
    totalTresorerieGlobale,
    isFetchingTresorerieGlobale,
    isDataReadyTresorerieGlobale,
  };
};

const useFetchAggregatedDataTreso = () => {
  const [aggregatedTreso, setAggregatedTreso] = useState([]);
  const [totalTreso, setTotalTreso] = useState(null);
  const [isFetchingTreso, setIsFetchingTreso] = useState(false);
  const [isDataReadyTreso, setIsDataReadyTreso] = useState(false);

  const startDateTreso = getFirstDayOfPreviousMonth();
  const endDateTreso = getLastDayOfPreviousMonth();

  const fetchAggregatedDataTreso = useCallback(async () => {
  console.log("Fetching aggregated data Treso...");
  console.log("Start Date Treso:", startDateTreso);
  console.log("End Date Treso:", endDateTreso);

  setIsFetchingTreso(true);

  try {
    const response = await axios.get(
      "https://gestionforage.terangapp.com/api/tresorerieglobale",
      {
        params: { startDate: startDateTreso, endDate: endDateTreso },
      }
    );

    console.log("API Response for Treso:", response);

    const { data } = response;

    // Check if data and required fields exist
    if (data && data.records) {
      // Fallback value if totalTresoDispo is missing
      const totalTresoDispo = data.records.totalTresoDispo || "N/A";

      // Ensure data.records is an array or set a fallback empty array
      const records = Array.isArray(data.records) ? data.records : [];

      // Add ID to each record if needed
      const dataWithIds = records.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      console.log("Formatted Treso Data with IDs:", dataWithIds);

      setAggregatedTreso(dataWithIds);
      setTotalTreso(totalTresoDispo);
      setIsDataReadyTreso(true);
    } else {
      console.error("Format de réponse API invalide: 'data.records' is missing");
      toast.error("Format de réponse API invalide. Veuillez réessayer.");
      setAggregatedTreso([]);
      setTotalTreso("N/A");
      setIsDataReadyTreso(false);
    }
  } catch (error) {
    console.error("Échec de la récupération des données agrégées Treso:", error);
    toast.error(
      "Échec de la récupération des données agrégées. Veuillez réessayer."
    );
    setAggregatedTreso([]);
    setTotalTreso("N/A");
    setIsDataReadyTreso(false);
  }

  setIsFetchingTreso(false);
}, [startDateTreso, endDateTreso]);

  useEffect(() => {
    fetchAggregatedDataTreso();
  }, [fetchAggregatedDataTreso]);

  return { aggregatedTreso, totalTreso, isFetchingTreso, isDataReadyTreso };
};

// =============================Fin Tresorerie Globale==============================

// ======================================Dettes=====================================

// ====================================Fin Dettes===================================
// =====================================Creances====================================
// ===================================Fin Creances==================================
const RapportGestion = () => {
  const theme = useTheme();
  const [startDate, setStartDate] = useState(getFirstDayOfMonth());
  const [endDate, setEndDate] = useState(getLastDayOfMonth());
  const { aggregatedConsommations, total, isFetching, isDataReady } =
    useFetchAggregatedData(startDate, endDate);
  const [aggregatedProductions, setAggregatedProductions] = useState([]);
  const [isFetChing, setIsFetChing] = useState(false);
  const [isDataready, setIsDataready] = useState(false);

  const fetchAggregatedData = useCallback(async () => {
    if (!startDate || !endDate) {
      console.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetChing(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/productions",
        {
          params: { startDate, endDate },
        }
      );
      const dataWithIds = response.data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setAggregatedProductions(dataWithIds);
      setIsDataready(true);
    } catch (error) {
      console.error("Failed to fetch aggregated productions:", error);
      toast.error("Failed to fetch aggregated productions. Please try again.");
      setIsDataready(false);
    }
    setIsFetChing(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchAggregatedData();
  }, [startDate, endDate, fetchAggregatedData]);

  const [
    aggregatedChargesFinancieresList,
    setAggregatedChargesFinancieresList,
  ] = useState([]);

  const [isFetchingChargesFin, setIsFetchingChargesFin] = useState(false);
  const [isDataReadyChargesFin, setIsDataReadyChargesFin] = useState(false);

  const fetchAggregatedDataChargesFin = useCallback(async () => {
    if (!startDate || !endDate) {
      console.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetchingChargesFin(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/chargesfinancieres",
        {
          params: { startDate, endDate },
        }
      );
      const dataWithIds = response.data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setAggregatedChargesFinancieresList(dataWithIds);
      setIsDataReadyChargesFin(true);
    } catch (error) {
      console.error("Failed to fetch aggregated charges financières:", error);
      toast.error(
        "Failed to fetch aggregated charges financières. Please try again."
      );
      setIsDataReadyChargesFin(false);
    }
    setIsFetchingChargesFin(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchAggregatedDataChargesFin();
  }, [startDate, endDate, fetchAggregatedDataChargesFin]);

  const [aggregatedRelevesIndicateurs, setAggregatedRelevesIndicateurs] =
    useState([]);

  const [isFetchingRelevesInd, setIsFetchingRelevesInd] = useState(false);
  const [isDataReadyRelevesInd, setIsDataReadyRelevesInd] = useState(false);

  const fetchAggregatedDataRelevesInd = useCallback(async () => {
    if (!startDate || !endDate) {
      console.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetchingRelevesInd(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/relevesindicateurs",
        {
          params: { startDate, endDate },
        }
      );
      const dataWithIds = response.data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setAggregatedRelevesIndicateurs(dataWithIds);
      setIsDataReadyRelevesInd(true);
    } catch (error) {
      console.error("Failed to fetch aggregated releves indicateurs:", error);
      toast.error(
        "Failed to fetch aggregated releves indicateurs. Please try again."
      );
      setIsDataReadyRelevesInd(false);
    }
    setIsFetchingRelevesInd(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchAggregatedDataRelevesInd();
  }, [startDate, endDate, fetchAggregatedDataRelevesInd]);
  // ===================================================================================
  const [aggregatedEntreeCaisse, setAggregatedEntreeCaisse] = useState([]);
  const [totalEntreeCaisse, setTotalEntreeCaisse] = useState(null);
  const [isFetchingEntreeCaisse, setIsFetchingEntreeCaisse] = useState(false);
  const [isDataReadyEntreeCaisse, setIsDataReadyEntreeCaisse] = useState(false);

  const fetchAggregatedDataEntreeCaisse = useCallback(async () => {
    if (!startDate || !endDate) {
      toast.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetchingEntreeCaisse(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/consommations/distribution",
        {
          params: { startDate, endDate },
        }
      );
      const dataWithIds = response.data.rows.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setAggregatedEntreeCaisse(dataWithIds);
      setTotalEntreeCaisse(response.data.total);
      setIsDataReadyEntreeCaisse(true);
    } catch (error) {
      console.error("Échec de la récupération des données agrégées:", error);
      toast.error(
        "Échec de la récupération des données agrégées. Veuillez réessayer."
      );
      setIsDataReadyEntreeCaisse(false);
    }
    setIsFetchingEntreeCaisse(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchAggregatedDataEntreeCaisse();
  }, [startDate, endDate, fetchAggregatedDataEntreeCaisse]);

  // ============================Sortie  Caisse==============================================

  const { sortieCaisse, totalSortie, isFetchingSortie, isDataReadySortie } =
    useFetchSortieCaisse(startDate, endDate);

  useEffect(() => {
    console.log("Sortie Caisse:", sortieCaisse);
    console.log("Total Sortie:", totalSortie);
    console.log("Is Data Ready Sortie:", isDataReadySortie);
  }, [sortieCaisse, totalSortie, isDataReadySortie]);

  const transformData = (data) => {
    if (data.length === 0) return [];
    const aggregatedData = data.reduce((acc, row) => {
      Object.keys(row).forEach((key) => {
        if (key !== "id" && key !== "selectedDate") {
          acc[key] = (acc[key] || 0) + parseFloat(row[key]);
        }
      });
      return acc;
    }, {});

    return Object.keys(aggregatedData).map((key) => ({
      id: key,
      categorie: key,
      montant: aggregatedData[key].toFixed(2), // format to two decimal places
    }));
  };

  const transformedData = transformData(sortieCaisse);

  // ================================Sortie Caisse Fin=========================================

  // ================================Situation Caisse=====================================
  const startDateTreso = getFirstDayOfPreviousMonth();
  const endDateTreso = getLastDayOfPreviousMonth();
  const [rows, setRows] = useState([]);
  const [isFetchingSituationCaisse, setIsFetchingSituationCaisse] =
    useState(false);
  const [isDataReadySituationCaisse, setIsDataReadySituationCaisse] =
    useState(false);

  const fetchTotalPaidAmount = useCallback(async () => {
    setIsFetchingSituationCaisse(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/consommations/tresoreriedebutmois",
        {
          params: { startDate, endDate },
        }
      );
      const totalPaidAmount = response.data.rows.reduce(
        (total, row) => total + (parseFloat(row.paidAmount) || 0),
        0
      );
      return totalPaidAmount;
    } catch (error) {
      console.error("Error FetchingSituationCaisse total paid amount:", error);
      toast.error("Erreur lors de la récupération du montant total payé.");
      return 0;
    } finally {
      setIsFetchingSituationCaisse(false);
    }
  }, [startDate, endDate]);

  const fetchTotalTreso = useCallback(async () => {
    setIsFetchingSituationCaisse(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/tresorerieglobale",
        {
          params: {
             startDate: startDateTreso, endDate: endDateTreso
          },
        }
      );

      const { records } = response.data;
      const totalTresoDispo = parseFloat(records.totalTresoDispo) || 0;
      return totalTresoDispo;
    } catch (error) {
      console.error("Error FetchingSituationCaisse total treso:", error);
      toast.error("Erreur lors de la récupération du montant total Treso.");
      return 0;
    } finally {
      setIsFetchingSituationCaisse(false);
    }
  }, [startDate, endDate]);

  const fetchTotalSortieCaisseFm = useCallback(async () => {
    setIsFetchingSituationCaisse(true);
    try {
      const response = await axios.get(
        "https://gestionforage.terangapp.com/api/sortiecaisse",
        {
          params: { startDate, endDate },
        }
      );

      const totalSortie = response.data.reduce(
        (acc, curr) => acc + (parseFloat(curr.total) || 0),
        0
      );

      return totalSortie;
    } catch (error) {
      console.error(
        "Error FetchingSituationCaisse total sortie caisse:",
        error
      );
      toast.error("Erreur lors de la récupération des données.");
      setIsFetchingSituationCaisse(false);
      return 0;
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const totalPaidAmount = await fetchTotalPaidAmount();
        const totalTreso = await fetchTotalTreso();
        const totalTresoDispo = totalPaidAmount + totalTreso;

        const totalSortie = await fetchTotalSortieCaisseFm();
        const totalSoldeCaisseFm = totalTresoDispo - totalSortie;
        const soldeEffCaisseFm = totalTresoDispo - totalSortie;

        if (typeof totalSortie !== "undefined") {
          setRows([
            {
              id: 1,
              totalCaisseFm: totalTresoDispo.toFixed(2),
              totalSortieCaisseFm: totalSortie.toFixed(2),
              totalSoldeCaisseFm: (totalTresoDispo - totalSortie).toFixed(2),
              soldeEffCaisseFm: (totalTresoDispo - totalSortie).toFixed(2),
              differenceAJustifier: (
                totalSoldeCaisseFm - soldeEffCaisseFm
              ).toFixed(2),
              totalTreso: totalTreso.toFixed(2),
            },
          ]);
        } else {
          console.error("Total Sortie is undefined.");
        }

        setIsDataReadySituationCaisse(true);
      } catch (error) {
        console.error("Error FetchingSituationCaisse data:", error);
        setIsDataReadySituationCaisse(false);
      }
    };

    fetchData();
  }, [fetchTotalPaidAmount, fetchTotalTreso, fetchTotalSortieCaisseFm]);

  // ================================Situation Caisse Fin=====================================

  // ==========================================Banque=========================================

  const [banque, setBanque] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [showTransactions, setShowTransactions] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const {
    aggregatedTransactions,
    totalTransactions,
    isFetchingTransactions,
    isDataReadyTransactions,
  } = useFetchAggregatedTransactions(startDate, endDate);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(
          "https://gestionforage.terangapp.com/api/transactions"
        );
        setTransactions(response.data);
        setFilteredTransactions(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Échec de la récupération des données de banque:", error);
      }
    };

    fetchTransactions();
  }, []);

  useEffect(() => {
    const fetchBanque = async () => {
      try {
        const response = await axios.get("https://gestionforage.terangapp.com/api/banque");
        setBanque(response.data);
      } catch (error) {
        console.error("Échec de la récupération des données de banque:", error);
      }
    };

    fetchBanque();
  }, []);

  // ========================================Fin Banque=======================================

  // ==================================Tresorerie Globale=====================================

  const {
    aggregatedConsommationsTresorerieGlobale,
    totalTresorerieGlobale,
    isFetchingTresorerieGlobale,
    isDataReadyTresorerieGlobale,
  } = useFetchAggregatedDataTresorerieGlobale(startDate, endDate);
  const { aggregatedTreso, totalTreso, isFetchingTreso, isDataReadyTreso } =
    useFetchAggregatedDataTreso(startDate, endDate);

  useEffect(() => {
    console.log(
      "Aggregated ConsommationsTresorerieGlobale:",
      aggregatedConsommationsTresorerieGlobale
    );
    console.log("Total:", totalTresorerieGlobale);
    console.log("Is Data Ready:", isDataReadyTresorerieGlobale);
  }, [
    aggregatedConsommationsTresorerieGlobale,
    totalTresorerieGlobale,
    isDataReadyTresorerieGlobale,
  ]);

  useEffect(() => {
    console.log("Aggregated Treso:", aggregatedTreso);
    console.log("Total Treso:", totalTreso);
    console.log("Is Data Ready Treso:", isDataReadyTreso);
  }, [aggregatedTreso, totalTreso, isDataReadyTreso]);
  // =================================Fin Tresorerie Globale==================================
  // =========================================Dettes==========================================
  const [formData, setFormData] = useState({
    id: null,
    selectedDate: getCurrentDate(),
    provenanceDette: "Fonctionnement",
    natureDette: "Caisse",
    valeurDette: 0,
    totauxDettes: 0,
  });

  const [detteList, setDetteList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredDettes, setFilteredDettes] = useState([]);

  useEffect(() => {
    fetchDettes();
  }, []);

  useEffect(() => {
    if (Array.isArray(detteList)) {
      setFilteredDettes(
        detteList.filter((dette) =>
          dette.provenanceDette
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setFilteredDettes([]);
    }
  }, [searchQuery, detteList]);

  const fetchDettes = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://gestionforage.terangapp.com/api/dettes");
      setDetteList(response.data || []); // Ensure response.data is an array or initialize as empty array
    } catch (error) {
      console.error("Error fetching dettes!", error);
      toast.error("Error fetching dettes. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  // =========================================Fin Dettes======================================
  // =======================================Creances==========================================
  const [creanceList, setCreanceList] = useState([]);
  const [filteredCreances, setFilteredCreances] = useState([]);

  useEffect(() => {
    fetchCreances();
  }, []);

  useEffect(() => {
    if (Array.isArray(creanceList)) {
      setFilteredCreances(
        creanceList.filter((creance) =>
          creance.provenanceCreance
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setFilteredCreances([]);
    }
  }, [searchQuery, creanceList]);

  const fetchCreances = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://gestionforage.terangapp.com/api/creances");
      setCreanceList(response.data || []); // Ensure response.data is an array or initialize as empty array
    } catch (error) {
      console.error("Error fetching creances!", error);
      toast.error("Error fetching creances. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  // =====================================Fin Creances==========================================

  // =========================================Infos===========================================

  const [aggregatedInfosList, setAggregatedInfosList] = useState([]);
  const [isFetchingAutresInfos, setIsFetchingAutresInfos] = useState(false);
  const [isDataReadyAutresInfos, setIsDataReadyAutresInfos] = useState(false);

  const fetchAggregatedDataAutresInfos = useCallback(async () => {
    if (!startDate || !endDate) {
      console.error("Veuillez sélectionner une plage de dates.");
      return;
    }
    setIsFetchingAutresInfos(true);
    try {
      const response = await axios.get("https://gestionforage.terangapp.com/api/Infos", {
        params: { startDate, endDate },
      });
      const dataWithIds = response.data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
      setAggregatedInfosList(dataWithIds);
      setIsDataReadyAutresInfos(true);
    } catch (error) {
      console.error("Failed to fetch aggregated autres informations:", error);
      toast.error(
        "Failed to fetch aggregated autres informations. Please try again."
      );
      setIsDataReadyAutresInfos(false);
    }
    setIsFetchingAutresInfos(false);
  }, [startDate, endDate]);

  useEffect(() => {
    fetchAggregatedDataAutresInfos();
  }, [startDate, endDate, fetchAggregatedDataAutresInfos]);

  // =======================================Fin Infos====================================

  const handlePrint = async () => {
    if (
      !isDataReady ||
      !isDataready ||
      !isDataReadyChargesFin ||
      !isDataReadyRelevesInd ||
      !isDataReadyAutresInfos ||
      !isDataReadyEntreeCaisse ||
      !isDataReadySortie ||
      !isDataReadySituationCaisse ||
      !isDataReadyTransactions ||
      !isDataReadyTresorerieGlobale ||
      !isDataReadyTreso
    ) {
      toast.error(
        "Les données ne sont pas prêtes. Veuillez sélectionner une plage de dates et réessayer."
      );
      return;
    }

    try {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(`
        <html>
        <head>
          <title>Rapport Gestion</title>
          <style>
            body { font-family: Arial, sans-serif; margin: 60px; }
            .header { display: flex; justify-content: space-between; align-items: center; }
            .header p { margin: 0; }
            h1 { text-align: center; }
            h2 { text-align: left; }
            table { width: 100%; border-collapse: collapse; margin-bottom: 20px; }
            th, td { border: 1px solid #000; padding: 8px; }
            th { background-color: #f2f2f2; }
            .content { margin-top: 20px; }
            .row { display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px; }
            .row .left, .row .right { flex: 1; }
            .input-row { display: flex; }
            .input-row .left { flex: 1; }
            .input-row .right { flex: 1; }           
          </style>
        </head>
        <body>
      `);

      printWindow.document.write(`
        <div class="header">
          <div><p>COPIFOR DE DAROU NGARAF</p></div>
          <div><p>Période du ${startDate} au ${endDate}</p></div>
        </div>
      `);

      printWindow.document.write(
        "<h1>Rapport Mensuel de Gestion de Forage</h1>"
      );
      printWindow.document.write(
        "<h2>Tableau de distribution d'eau du mois</h2>"
      );
      printWindow.document.write("<table border='1'>");
      printWindow.document.write(`
      <tr>
        <th>Catégorie de points de distribution d'eau</th>
        <th>Nombre de points de distribution</th>
        <th>Volume d'eau distribué</th>
        <th>Recettes perçues</th>
      </tr>
    `);

      if (aggregatedConsommations.length > 0) {
        aggregatedConsommations.forEach((item) => {
          printWindow.document.write(`
          <tr>
            <td>${item.typeBranchement}</td>
            <td>${item.nombres}</td>
            <td>${item.nombreMd}</td>
            <td>${item.paidAmount}</td>
          </tr>
        `);
        });
        printWindow.document.write(`
        <tr>
          <td><strong>Total</strong></td>
          <td>${total.nombres}</td>
          <td>${total.nombreMd}</td>
          <td>${total.paidAmount}</td>
        </tr>
      `);
      } else {
        printWindow.document.write(`
        <tr><td colspan="4">Aucune donnée disponible</td></tr>
      `);
      }

      printWindow.document.write("</table>");
      printWindow.document.write("<h2>Tableau de production du mois</h2>");
      printWindow.document.write("<table border='1'>");
      printWindow.document.write(`
        <tr>
          <th>Designation</th>
          <th>Unités</th>
          <th>Valeur</th>
        </tr>
      `);

      if (aggregatedProductions.length > 0) {
        aggregatedProductions.forEach((item) => {
          printWindow.document.write(`
            <tr>
              <td>Nombre m3 Pompé</td>
              <td>${item.nombreMpUnit}</td>
              <td>${item.nombreMp}</td>
            </tr>
            <tr>
              <td>Durée Pompage</td>
              <td>${item.dureePompageUnit}</td>
              <td>${item.dureePompage}</td>
            </tr>
            <tr>
              <td>Gazoil Consommé</td>
              <td>${item.gazoilConsommeUnit}</td>
              <td>${item.gazoilConsomme}</td>
            </tr>
            <tr>
              <td>Électricité Consommée</td>
              <td>${item.electriciteConsommeUnit}</td>
              <td>${item.electriciteConsomme}</td>
            </tr>
            <tr>
              <td>Nombre Jours Panne</td>
              <td>${item.nombreJourPanneUnit}</td>
              <td>${item.nombreJourPanne}</td>
            </tr>
            <tr>
              <td>Visite Maint Préventive</td>
              <td>${item.visiteMaintPrevUnit}</td>
              <td>${item.visiteMaintPrev}</td>
            </tr>
            <tr>
              <td>Vidange Moteur</td>
              <td>${item.vidangeMoteurUnit}</td>
              <td>${item.vidangeMoteur}</td>
            </tr>
          `);
        });
      } else {
        printWindow.document.write(`
          <tr><td colspan="3">Aucune donnée disponible</td></tr>
        `);
      }

      printWindow.document.write("</table>");
      printWindow.document.write(
        "<h2>Tableau de charges financières du mois</h2>"
      );
      printWindow.document.write("<table border='1'>");
      printWindow.document.write(`
        <tr>
          <th>Designation</th>
          <th>Montant</th>
        </tr>
      `);

      if (aggregatedChargesFinancieresList.length > 0) {
        aggregatedChargesFinancieresList.forEach((item) => {
          printWindow.document.write(`
            <tr>
              <td>Versement</td>
              <td>${item.versement}</td>
            </tr>
            <tr>
              <td>Dépenses Système</td>
              <td>${item.depensesSysteme}</td>
            </tr>
            <tr>
              <td>Dépenses Réseau Distribution</td>
              <td>${item.depensesRxDistribution}</td>
            </tr>
            <tr>
              <td>Dépenses Système Exhaure</td>
              <td>${item.depensesSystemeExhaure}</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>${item.total}</td>
            </tr>
          `);
        });
      } else {
        printWindow.document.write(`
          <tr><td colspan="2">Aucune donnée disponible</td></tr>
        `);
      }

      printWindow.document.write("</table>");
      printWindow.document.write(
        "<h2>Tableau de relevés indicateurs du mois</h2>"
      );
      printWindow.document.write("<table border='1'>");
      printWindow.document.write(`
        <tr>
          <th>Designation</th>
          <th>Unités</th>
          <th>Valeur</th>
        </tr>
      `);

      if (aggregatedRelevesIndicateurs.length > 0) {
        aggregatedRelevesIndicateurs.forEach((item) => {
          printWindow.document.write(`
            <tr>
              <td>Index Compteur Horaire Pompe</td>
              <td>${item.indexCompteurHorairePompeUnit}</td>
              <td>${item.indexCompteurHorairePompe}</td>
            </tr>
            <tr>
              <td>Index Compteur Eau Pompe</td>
              <td>${item.indexCompteurEauPompeUnit}</td>
              <td>${item.indexCompteurEauPompe}</td>
            </tr>
            <tr>
              <td>Index Compteur Électricité</td>
              <td>${item.indexCompteurElectriciteUnit}</td>
              <td>${item.indexCompteurElectricite}</td>
            </tr>
            <tr>
              <td>Solde Compte Epargne Asufor</td>
              <td>${item.soldeCompteEpargneAsuforUnit}</td>
              <td>${item.soldeCompteEpargneAsufor}</td>
            </tr>
            <tr>
              <td>Niveau Statique Avant Pompage</td>
              <td>${item.niveauStatiqueAvPompageUnit}</td>
              <td>${item.niveauStatiqueAvPompage}</td>
            </tr>
          `);
        });
      } else {
        printWindow.document.write(`
          <tr><td colspan="3">Aucune donnée disponible</td></tr>
        `);
      }
      // =================================================
      printWindow.document.write("<table border='1'>");
      printWindow.document.write("<h2>Tableau des entrées caisse du mois</h2>");
      printWindow.document.write(`
        <tr>
          <th>Catégorie de points de distribution d'eau</th>
          <th>Nombre de points de distribution</th>
          <th>Volume d'eau distribué</th>
          <th>Recettes perçues</th>
        </tr>
      `);

      if (aggregatedEntreeCaisse.length > 0) {
        aggregatedEntreeCaisse.forEach((item) => {
          printWindow.document.write(`
            <tr>
              <td>${item.typeBranchement}</td>
              <td>${item.nombres}</td>
              <td>${item.nombreMd}</td>
              <td>${item.paidAmount}</td>
            </tr>
          `);
        });
        printWindow.document.write(`
          <tr>
            <td><strong>Total</strong></td>
            <td>${totalEntreeCaisse.nombres}</td>
            <td>${totalEntreeCaisse.nombreMd}</td>
            <td>${totalEntreeCaisse.paidAmount}</td>
          </tr>
        `);
      } else {
        printWindow.document.write(`
          <tr><td colspan="4">Aucune donnée disponible</td></tr>
        `);
      }
      // ================================Fin Entree Caisse========================

      // ================================Sortie Caisse============================

      printWindow.document.write("<table border='1'>");
      printWindow.document.write(
        "<h2>Tableau de sortie de caisse du mois</h2>"
      );
      printWindow.document.write(`
        <tr>
          <th>Catégorie</th>
          <th>Montant</th>
        </tr>
      `);

      if (transformedData.length > 0) {
        transformedData.forEach((item) => {
          printWindow.document.write(`
            <tr>
              <td>${item.categorie}</td>
              <td>${item.montant}</td>
            </tr>
          `);
        });
        printWindow.document.write(`
          <tr>
            <td><strong>Total</strong></td>
            <td>${totalSortie}</td>
          </tr>
        `);
      } else {
        printWindow.document.write(`
          <tr><td colspan="2">Aucune donnée disponible</td></tr>
        `);
      }
      // ===============================Fin Sortie Caisse=========================

      // =================================Banque==================================

      printWindow.document.write("<table border='1'>");
      printWindow.document.write("<h2>Tableau de transactions du mois</h2>");
      printWindow.document.write(`
      <tr>
        <th>Numéro de Compte Bancaire</th>
        <th>Versement</th>
        <th>Retrait</th>
      </tr>
    `);

      if (aggregatedTransactions.length > 0) {
        aggregatedTransactions.forEach((item) => {
          printWindow.document.write(`
          <tr>
            <td>${item.numeroCompteBancaire}</td>
            <td>${item.versement}</td>
            <td>${item.retrait}</td>
          </tr>
        `);
        });
        printWindow.document.write(`
        <tr>
          <td><strong>Total</strong></td>
          <td>${totalTransactions.versement}</td>
          <td>${totalTransactions.retrait}</td>
        </tr>
      `);
      } else {
        printWindow.document.write(`
        <tr><td colspan="4">Aucune donnée disponible</td></tr>
      `);
      }

      //  ==============================Fin Banque================================

      // ============================Tresorerie Globale===========================

      printWindow.document.write("<table border='1'>");
      printWindow.document.write("<h2>Tableau de trésorerie du mois</h2>");
      printWindow.document.write(`
      <tr>
        <td colspan="3"><strong>Total Caisse Début du Mois</strong></td>
        <td>${totalTreso}</td>
      </tr>
    `);
      printWindow.document.write(`
        <tr>
          <th>Catégorie de points de distribution d'eau</th>
          <th>Nombre de points de distribution</th>
          <th>Volume d'eau distribué</th>
          <th>Recettes perçues</th>
        </tr>
      `);

      if (aggregatedConsommationsTresorerieGlobale.length > 0) {
        aggregatedConsommationsTresorerieGlobale.forEach((item) => {
          printWindow.document.write(`
            <tr>
              <td>${item.typeBranchement}</td>
              <td>${item.nombres}</td>
              <td>${item.nombreMd}</td>
              <td>${item.paidAmount}</td>
            </tr>
          `);
        });
        printWindow.document.write(`
          <tr>
            <td><strong>Total</strong></td>
            <td>${totalTresorerieGlobale.nombres}</td>
            <td>${totalTresorerieGlobale.nombreMd}</td>
            <td>${totalTresorerieGlobale.paidAmount}</td>
          </tr>
        `);
        printWindow.document.write(`
          <tr>
            <td colspan="3"><strong>Total des entrées caisse du mois</strong></td>
            <td>${totalTresorerieGlobale.paidAmount}</td>
          </tr>
        `);
        printWindow.document.write(`
        <tr>
          <td colspan="3"><strong>Total Caisse fin du mois</strong></td>
          <td>${
            parseFloat(totalTresorerieGlobale.paidAmount) +
            parseFloat(totalTreso)
          }</td>
        </tr>
      `);
      } else {
        printWindow.document.write(`
          <tr><td colspan="4">Aucune donnée disponible</td></tr>
        `);
      }

      //  =======================Fin Tresorerie Globale===========================

      // =============================Situation Dettes============================
      printWindow.document.write("<table border='1'>");
      printWindow.document.write("<h2>Tableau des Dettes</h2>");
      printWindow.document.write(`
      <html lang="fr">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 60px;
          }
          .header {
            text-align: center;
            margin-bottom: 10px; /* Added margin to separate header from content */
          }
          .header p {
            margin: 0;
          }
          h1 {
            margin-bottom: 10px;
          }
          h2 {
            text-align: left;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
          }
          th, td {
            border: 1px solid #000;
            padding: 8px;
          }
          th {
            background-color: #f2f2f2;
          }
        </style>
      </head>
      <body>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Provenance de la dette</th>
              <th>Nature de la dette</th>
              <th>Valeur de la dette</th>
              <th>Totaux dettes</th>
            </tr>
          </thead>
          <tbody>
            ${filteredDettes
              .map(
                (dette) => `
                <tr>
                  <td>${dette.id}</td>
                  <td>${dette.selectedDate}</td>
                  <td>${dette.provenanceDette}</td>
                  <td>${dette.natureDette}</td>
                  <td>${dette.valeurDette}</td>
                  <td>${dette.totauxDettes}</td>
                </tr>
              `
              )
              .join("")}
          </tbody>
        </table>
      </body>
      </html>
      `);

      //  =========================Fin Situation Dettes===========================

      // ===========================Situation Creances============================

      printWindow.document.write("<table border='1'>");
      printWindow.document.write("<h2>Tableau des Créances</h2>");
      printWindow.document.write(`
      <html lang="fr">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 60px;
          }
          .header {
            text-align: center;
            margin-bottom: 10px; /* Added margin to separate header from content */
          }
          .header p {
            margin: 0;
          }
          h1 {
            margin-bottom: 10px;
          }
          h2 {
            text-align: left;
          }
          table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
          }
          th, td {
            border: 1px solid #000;
            padding: 8px;
          }
          th {
            background-color: #f2f2f2;
          }
        </style>
      </head>
      <body>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Date</th>
              <th>Provenance de la créance</th>
              <th>Nature de la créance</th>
              <th>Valeur de la créance</th>
              <th>Totaux créances</th>
            </tr>
          </thead>
          <tbody>
            ${filteredCreances
              .map(
                (creance) => `
                <tr>
                  <td>${creance.id}</td>
                  <td>${creance.selectedDate}</td>
                  <td>${creance.provenanceCreance}</td>
                  <td>${creance.natureCreance}</td>
                  <td>${creance.valeurCreance}</td>
                  <td>${creance.totauxCreances}</td>
                </tr>
              `
              )
              .join("")}
          </tbody>
        </table>
      </body>
      </html>
      `);

      //  ========================Fin Situation Creances==========================

      // ======================================Infos==============================
      printWindow.document.write("</table>");

      if (aggregatedInfosList.length > 0) {
        printWindow.document.write('<div class="fixed-bottom">');
        aggregatedInfosList.forEach((item) => {
          printWindow.document.write(`
            <div class="content">
              <div class="row">
                <div class="left"><strong>${item.title_left}</strong></div>
                <div class="right"><strong>${item.title_right}</strong></div>
              </div>
              <div class="input-row">
                <div class="left"><p>${item.input_field}</p></div>
                <div class="right"></div>
              </div>
              <div class="row">
                <div class="left"><strong>${item.bottom_title}</strong></div>
              </div>
            </div>
          `);
        });
        printWindow.document.write("</div>");
      } else {
        printWindow.document.write(`
          <div class="fixed-bottom"><p>Aucune donnée disponible</p></div>
        `);
      }
      //  ==========================Fin Infos====================================
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
      toast.success("Rapport gestion imprimé avec succès.");
    } catch (error) {
      console.error("Failed to print:", error);
      toast.error(
        "Échec de l'impression du rapport de gestion. Veuillez réessayer."
      );
    }
  };

  const columns = [
    {
      field: "typeBranchement",
      headerName: "Catégorie de points de distribution d'eau",
      flex: 1,
    },
    {
      field: "nombres",
      headerName: "Nombre de points de distribution",
      flex: 1,
    },
    { field: "nombreMd", headerName: "Volume d'eau distribué", flex: 1 },
    { field: "paidAmount", headerName: "Recettes perçues", flex: 1 },
  ];

  const column = [
    { field: "nombreMp", headerName: "Nombre m3 Pompé", flex: 1 },
    { field: "dureePompage", headerName: "Durée Pompage", flex: 1 },
    { field: "gazoilConsomme", headerName: "Gazoil Consommé", flex: 1 },
    {
      field: "electriciteConsomme",
      headerName: "Électricité Consommée",
      flex: 1,
    },
    { field: "nombreJourPanne", headerName: "Nombre Jours Panne", flex: 1 },
    {
      field: "visiteMaintPrev",
      headerName: "Visite Maint Préventive",
      flex: 1,
    },
    { field: "vidangeMoteur", headerName: "Vidange Moteur", flex: 1 },
  ];

  const columnsChargesFin = [
    {
      field: "versement",
      headerName: "Versement",
      flex: 1,
    },
    {
      field: "depensesSysteme",
      headerName: "Dépenses Système",
      flex: 1,
    },
    {
      field: "depensesRxDistribution",
      headerName: "Dépenses Réseau Distribution",
      flex: 1,
    },
    {
      field: "depensesSystemeExhaure",
      headerName: "Solde Compte Epargne Asufor",
      flex: 1,
    },
    {
      field: "total",
      headerName: "Total",
      flex: 1,
    },
  ];

  const columnsRelevesInd = [
    {
      field: "indexCompteurHorairePompe",
      headerName: "Index Compteur Horaire Pompe",
      flex: 1,
    },
    {
      field: "indexCompteurEauPompe",
      headerName: "Index Compteur Eau Pompe",
      flex: 1,
    },
    {
      field: "indexCompteurElectricite",
      headerName: "Index Compteur Électricité",
      flex: 1,
    },
    {
      field: "soldeCompteEpargneAsufor",
      headerName: "Solde Compte Epargne Asufor",
      flex: 1,
    },
    {
      field: "niveauStatiqueAvPompage",
      headerName: "Niveau Statique Avant Pompage",
      flex: 1,
    },
  ];

  const columnsEntreeCaisse = [
    {
      field: "typeBranchement",
      headerName: "Catégorie de points de distribution d'eau",
      flex: 1,
    },
    {
      field: "nombres",
      headerName: "Nombre de points de distribution",
      flex: 1,
    },
    { field: "nombreMd", headerName: "Volume d'eau distribué", flex: 1 },
    { field: "paidAmount", headerName: "Recettes perçues", flex: 1 },
  ];

  const columnsSortieCaisse = [
    { field: "categorie", headerName: "Catégorie", flex: 1 },
    { field: "montant", headerName: "Montant", flex: 1 },
  ];

  const columnsSituationCaisse = [
    { field: "totalCaisseFm", headerName: "Total Caisse Fin du Mois", flex: 1 },
    {
      field: "totalSortieCaisseFm",
      headerName: "Total Sortie Caisse",
      flex: 1,
    },
    { field: "totalSoldeCaisseFm", headerName: "Total Solde Caisse", flex: 1 },
    { field: "soldeEffCaisseFm", headerName: "Solde Effectif Caisse", flex: 1 },
    {
      field: "differenceAJustifier",
      headerName: "Différence à Justifier",
      flex: 1,
    },
    { field: "totalTreso", headerName: "Total Trésorerie", flex: 1 },
  ];

  const columnsBanques = [
    { field: "id", headerName: "ID", width: 40 },
    { field: "selectedDate", headerName: "Date", width: 90 },
    {
      field: "numeroCompteBancaire",
      headerName: "Numéro Compte Bancaire",
      width: 150,
    },
    { field: "natureCompte", headerName: "Nature Compte", width: 150 },
    { field: "versement", headerName: "Versement", width: 150 },
    { field: "retrait", headerName: "Retrait", width: 120 },
    { field: "solde", headerName: "Solde", width: 100 },
  ];

  const columnsDettes = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "selectedDate", headerName: "Date", width: 150 },
    { field: "provenanceDette", headerName: "Provenance", width: 150 },
    { field: "natureDette", headerName: "Nature", width: 150 },
    { field: "valeurDette", headerName: "Valeur", width: 130 },
    { field: "totauxDettes", headerName: "Total Dettes", width: 130 },
  ];

  const columnsCreances = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "selectedDate", headerName: "Date", width: 150 },
    { field: "provenanceCreance", headerName: "Provenance", width: 150 },
    { field: "natureCreance", headerName: "Nature", width: 150 },
    { field: "valeurCreance", headerName: "Valeur", width: 130 },
    { field: "totauxCreances", headerName: "Total Creances", width: 130 },
  ];

  const columnsInfos = [
    {
      field: "title_left",
      headerName: "Title Left",
      flex: 1,
    },
    {
      field: "title_right",
      headerName: "Title Right",
      flex: 1,
    },
    {
      field: "input_field",
      headerName: "Input Field",
      flex: 1,
    },
    {
      field: "bottom_title",
      headerName: "Bottom Title",
      flex: 1,
    },
  ];

  const columnsTresorerieGlobale = [
    {
      field: "typeBranchement",
      headerName: "Catégorie de points de distribution d'eau",
      flex: 1,
    },
    {
      field: "nombres",
      headerName: "Nombre de points de distribution",
      flex: 1,
    },
    { field: "nombreMd", headerName: "Volume d'eau distribué", flex: 1 },
    { field: "paidAmount", headerName: "Recettes perçues", flex: 1 },
  ];

  const columnsTotalTreso = [
    {
      field: "totalTresoDispo",
      headerName: "Trésorerie Début de Mois",
      flex: 1,
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="h1" gutterBottom>
            Liste des Données de Gestion
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="secondary"
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginTop: "1px",
              marginRight: "5px",
            }}
            onClick={handlePrint}
            disabled={isFetching || !isDataReady}
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Télécharger le rapport de gestion
          </Button>
           <TextField
            id="start-date"
            label="Période de début"
            type="date"
            value={convertToYYYYMMDD(startDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setStartDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="end-date"
            label="Période de fin"
            type="date"
            value={convertToYYYYMMDD(endDate)} // Convertir dd-MM-yyyy en yyyy-MM-dd pour TextField
            onChange={(e) => setEndDate(formatDateToDDMMYYYY(e.target.value))} // Convertir yyyy-MM-dd en dd-MM-yyyy pour l'état
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="-20px"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Distribution d'eau
        </Typography>
      </Box>
      <Box
        mt="20px"
        mb="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={aggregatedConsommations}
          columns={columns}
          getRowId={(row) => row.id}
          loading={isFetching}
          components={{
            Footer: () => (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                padding="10px"
                bgcolor={theme.palette.background.alt}
              >
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  <strong>Total</strong>
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  {total ? `${total.nombres}` : "0"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  {total ? `${total.nombreMd}` : "0"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  {total ? `${total.paidAmount}` : "0"}
                </Typography>
              </Box>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="-20px"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Production d'eau
        </Typography>
      </Box>
      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={aggregatedProductions}
          columns={column}
          getRowId={(row) => row.id}
          loading={isFetChing}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="-20px"
        mt="20px"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Charges financières
        </Typography>
      </Box>
      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={aggregatedChargesFinancieresList}
          columns={columnsChargesFin}
          getRowId={(row) => row.id}
          loading={isFetchingChargesFin}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="-20px"
        mt="20px"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Relevés indicateurs
        </Typography>
      </Box>
      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={aggregatedRelevesIndicateurs}
          columns={columnsRelevesInd}
          getRowId={(row) => row.id}
          loading={isFetchingRelevesInd}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="-20px"
        mt="20px"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Entrées caisse
        </Typography>
      </Box>
      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={aggregatedEntreeCaisse}
          columns={columnsEntreeCaisse}
          getRowId={(row) => row.id}
          loading={isFetchingEntreeCaisse}
          components={{
            Footer: () => (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                padding="10px"
                bgcolor={theme.palette.background.alt}
              >
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  <strong>Total</strong>
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  {totalEntreeCaisse ? `${totalEntreeCaisse.nombres}` : "0"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  {totalEntreeCaisse ? `${totalEntreeCaisse.nombreMd}` : "0"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  {totalEntreeCaisse ? `${totalEntreeCaisse.paidAmount}` : "0"}
                </Typography>
              </Box>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="-20px"
        mt="20px"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Sortie caisse
        </Typography>
      </Box>
      <Box
        mt="10px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={transformedData}
          columns={columnsSortieCaisse}
          getRowId={(row) => row.id}
          loading={isFetchingSortie}
          components={{
            Footer: () => (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                padding="10px"
                bgcolor={theme.palette.background.alt}
              >
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  {isDataReadySortie ? (
                    <>
                      Total: <strong>{totalSortie || 0} FCFA</strong>
                    </>
                  ) : (
                    "Calcul du total en cours..."
                  )}
                </Typography>
              </Box>
            ),
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="-20px"
        mt="20px"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Situation Caisse
        </Typography>
      </Box>
      <Box
        mt="10px"
        height="25vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid rows={rows} columns={columnsSituationCaisse} autoHeight />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="-20px"
        mt="20px"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Transactions Bancaires
        </Typography>
      </Box>
      <Grid item xs={12}>
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          {showTransactions && (
            <DataGrid
              loading={isLoading}
              rows={[...filteredTransactions].sort((a, b) => {
                // Sort by selectedDate (descending)
                if (a.selectedDate < b.selectedDate) return 1;
                if (a.selectedDate > b.selectedDate) return -1;
                // If selectedDate is the same, sort by id (ascending)
                return b.id - a.id;
              })}
              columns={columnsBanques}
            />
          )}
        </Box>
      </Grid>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="-20px"
        mt="20px"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Trésorerie Globale
        </Typography>
      </Box>
      <Box
        mt="10px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={aggregatedConsommationsTresorerieGlobale}
          columns={columnsTresorerieGlobale}
          getRowId={(row) => row.id}
          loading={isFetchingTresorerieGlobale}
          components={{
            Footer: () => (
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                padding="10px"
                bgcolor={theme.palette.background.alt}
              >
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  <strong>Total Recettes du mois</strong>
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  {totalTresorerieGlobale
                    ? `${totalTresorerieGlobale.nombres}`
                    : "0"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  {totalTresorerieGlobale
                    ? `${totalTresorerieGlobale.nombreMd}`
                    : "0"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="div"
                  sx={{ flex: 1 }}
                >
                  {totalTresorerieGlobale
                    ? `${
                        parseFloat(totalTresorerieGlobale.paidAmount) +
                        parseFloat(totalTreso)
                      }`
                    : "0"}
                </Typography>
              </Box>
            ),
          }}
        />
      </Box>
      <DataGrid
        rows={aggregatedTreso}
        columns={columnsTotalTreso}
        getRowId={(row) => row.id}
        loading={isFetchingTreso}
        components={{
          Footer: () => (
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              padding="10px"
              bgcolor={theme.palette.background.alt}
            >
              {isFetchingTreso && <p>Loading...</p>}
              {!isFetchingTreso && aggregatedTreso.length === 0 && (
                <p>No data available</p>
              )}
              <Typography variant="subtitle1" component="div" sx={{ flex: 3 }}>
                <strong>Trésorerie Début de Mois</strong>
              </Typography>
              <Typography variant="subtitle1" component="div" sx={{ flex: 1 }}>
                {totalTreso || "0"}
              </Typography>
            </Box>
          ),
        }}
      />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="-20px"
        mt="20px"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Dettes
        </Typography>
      </Box>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={loading}
          rows={
            Array.isArray(filteredDettes)
              ? [...filteredDettes].sort((a, b) => {
                  if (a.selectedDate < b.selectedDate) return 1;
                  if (a.selectedDate > b.selectedDate) return -1;
                  return b.id - a.id;
                })
              : []
          }
          columns={columnsDettes}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="-20px"
        mt="20px"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Créances
        </Typography>
      </Box>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={loading}
          rows={
            Array.isArray(filteredCreances)
              ? [...filteredCreances].sort((a, b) => {
                  if (a.selectedDate < b.selectedDate) return 1;
                  if (a.selectedDate > b.selectedDate) return -1;
                  return b.id - a.id;
                })
              : []
          }
          columns={columnsCreances}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="-20px"
        mt="20px"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Informations
        </Typography>
      </Box>
      <Box
        mt="20px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
          },
          "& .MuiCheckbox-root": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={aggregatedInfosList}
          columns={columnsInfos}
          getRowId={(row) => row.id}
          loading={isFetchingAutresInfos}
        />
      </Box>
    </Box>
  );
};

export default RapportGestion;
     
